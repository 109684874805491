.slot-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
}
  
.slot-item {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  transition: transform 0.1s ease-in-out;
}
  
button {
  font-size: 1rem;
  cursor: pointer;
}  