.main-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.menu-title {
    width: 90%;
    font-size: 2em;
    text-align: center;
    font-weight: 700;
}

.menu-container {
    position: relative;
    width: 100%;
    padding: 4em;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 5em;
}

.menu {
    width: 55%;
    height: 40vh;
    background-size: cover;
    background-position: center;
    border: solid black 1px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    cursor: pointer;
    transition: width 0.5s ease, height 0.5s ease, transform 0.5s ease, box-shadow 0.5s ease;
}

.menu:hover {
    width: 56%;
    height: 41vh;
    transform: translateY(-5px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.quiz {
    background-image: url('../../assets/english-quiz-tests.png');
}

.word {
    background-image: url('../../assets/learn-words.png');
}

.irregular {
    background-image: url('../../assets/english-irregular-verbs.png');
}

.listening {
    background-image: url('../../assets/english-listening.png');
}

.articles {
    background-image: url('../../assets/learn-articles-english.png');
}

.text-menu {
    font-size: 2em;
    text-align: center;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: solid black 2px;
    border-radius: 20px;
    padding: 0.5em 0.5em 0.5em 0.5em;
    background-color: rgb(255, 255, 255, 0.9);
    margin-top: -1em;
    font-weight: 700;
}

.feedback-container-menu {
    color: gray;
    font-size: 0.9em;
    text-align: center;
    margin: 1em;
}

.menu-content {
    line-height: 2em;
    width: 80%;
    font-size: large;
}

.menu-content h1,h2{
    text-align: center;
}

.new-tag {
    border-radius: 20px;
    background-color: rgba(135, 244, 18, 0.9);
}

.new-tag p {
    font-size: small;
}

@media (max-width: 900px) {
    .menu-container {
        flex-direction: column;
        gap: 4em;
    }

    .menu-content {
        width: 90%;
    }

    .menu {
        width: 90%;
        height: 25vh;
    }

    .menu:hover {
        width: 92%;
        height: 27vh;
        transform: translateY(-3px);
        box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
    }

    .text-menu {
        font-size: 1.5em;
    }
}