.IrregularVerbs {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.IrregularVerbs th {
    background-color: rgb(206, 206, 206);
}

.irregular-table {
    width: 70%;
}

.irregular-spinner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3em;
}

.irregular-base,
.irregular-participle,
.irregular-simple {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5em;
}

.irregular-spinner input {
    width: 100%;
    text-align: center;
    font-size: large;
}

.irregular-panel {
    display: flex;
    justify-content: space-evenly;
}

.button-orange {
    background-color: #FF7F50; /* Coral Orange */
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 30%;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE 10+ */
}

.button-orange:hover {
    background-color: #FF6347; /* Tomato Orange */
    transform: translateY(-2px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.button-orange:active {
    background-color: #E55339; /* Slightly darker orange */
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Green Button Style */
.button-green {
    background-color: #229322; /* Lime Green */
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 30%;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE 10+ */
}

.button-green:hover {
    background-color: #28A745; /* Darker Green */
    transform: translateY(-2px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.button-green:active {
    background-color: #218838; /* Even darker green */
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.irregural-quiz {
    display: flex;
    flex-direction: column;
    gap: 2em;
    margin-bottom: 2em;
}

.irregular-base div {
    width: 100%;
    text-align: center;
}

.irregular-title {
    color: gray;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE 10+ */
}

.about-irregular-verbs {
    width: 70%;
    margin-bottom: 2em;
}

.about-irregular-verbs h1{
    text-align: center;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
}

.about-irregular-verbs h2{
    text-align: center;
    margin-bottom: 1em;
}

.about-irregular-verbs h3{
    text-align: center;
    margin-bottom: 1em;
}

.about-irregular-verbs p {
    text-indent: 20px;
    line-height: 1.5em;
}

.about-irregular-verbs li {
    text-indent: 10px;
    line-height: 1.5em;
    margin-bottom: 1em;
}

@media (max-width: 700px) {
    .irregular-spinner {
        flex-direction: column;
        gap: 1.5em;
    }

    .irregular-panel {
        flex-direction: column-reverse;
        align-items: center;
        gap: 1em;
    }

    .button-green,
    .button-orange {
        width: 80%;
    }

    .irregural-quiz {
        width: 70%;
    }

    .irregular-base,
    .irregular-participle,
    .irregular-simple {
        width: 100%;
    }
}