.PrivacyPolicy {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pp-block {
    width: 80%;
    padding: 2em;
    display: flex;
    flex-direction: column;
    gap: 2em;
}

.pp-block li {
    line-height: 2em;
    text-indent: 20px;
}

@media (max-width: 700px) {
    .pp-block {
        width: 100%;
    }
}