.Articles {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.article-container {
    padding: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
}

.article-button-container {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.article-button-container button {
    width: 20%;
}

.about-articles p {
    font-size: large;
    text-indent: 10px;
    line-height: 1.5em;
}

.about-articles li {
    font-size: large;
    text-indent: 10px;
    line-height: 1.5em;
}

.about-articles h1, h2{
    text-align: center;
    margin-top: 1em;
    margin-bottom: 1em;
}

.article-word {
    padding: 0.25em 0.5em 0.25em 0.5em;
    border: solid 1px gray;
    border-radius: 10px;
}

@media (max-width: 700px) {
    .article-button-container {
        width: 100%;
    }

    .article-container {
        padding: 1.5em;
    }
}