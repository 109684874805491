.word-match {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    gap: 1em;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow-x: hidden;
    padding-bottom: 25px;
    padding-top: 25px;
}

.exam-tip-text {
    font-size: 1em;
    color: gray;
    text-align: center;
}

.exam-result-text {
    font-size: 1.5em;
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 500;
}

.exam-result-button {
    margin: 1em;
    padding: 0.5em;
    border: solid 2px green;
    border-radius: 15px;
    font-weight: 600;
    cursor: pointer;
}

.loading-words {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-div {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background-size: cover;
    background-position: center;
    border: solid black 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.swiper-div-fake {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background-size: cover;
    background-position: center;
    filter: grayscale(90%);
    border: solid black 1px;
}

.cards-container {
    position: relative;
    width: 40%;
    height: 90%;
}

.main-container {
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.words-header {
    width: 93%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.word-container {
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    cursor: grab;
}

.word-name {
    color: black;
    text-align: center;
    border: solid black 2px;
    border-radius: 20px;
    padding: 1em 1em 1em 1em;
    background-color: rgb(255, 255, 255, 0.9);
    display: flex;
    flex-direction: column;
}

.word-name-text {
    color: black;
    font-size: 2em;
    text-align: center;
    font-weight: 700;
}

.word-phonetic {
    font-size: 1.5em;
    text-align: center;
}

.word-description {
    width: 90%;
    font-size: 1.3em;
    text-align: center;
    border: solid black 2px;
    border-radius: 20px;
    padding: 0.5em 0.5em 0.5em 0.5em;
    background-color: rgb(255, 255, 255, 0.95);
    font-weight: 550;
}

.menu-button-words {
    cursor: pointer;
    font-weight: 700;
}

.tip-mobile {
    display: none;
    font-weight: 600;
    text-align: center;
}

.tip-left {
    width: 20%;
    color: gray;
    font-size: 0.8em;
    font-weight: 600;
    text-align: center;
}

.tip-right {
    width: 20%;
    color: gray;
    font-size: 0.8em;
    font-weight: 600;
    text-align: center;
}

.progress-words-container {
    position: relative;
    width: 60%;
    display: flex;
    align-items: center;
    gap: 1em;
}

.progress-bar-container {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
}

.progress-words-text {
    position: absolute;
    left: 1em;
    font-weight: 600;
}

.progress-icon {
    width: 40px;
    height: 40px;
    background-image: url('../../assets/vocab-boost-icon-words.png');
    background-size: cover;
    background-position: center;
    cursor: pointer;
}

.exam-icon {
    width: 40px;
    height: 40px;
    background-image: url('../../assets/vacablurary-exam-test-icon.png');
    background-size: cover;
    background-position: center;
    cursor: pointer;
}

.words-exam-lost {
    width: 100px;
    height: 100px;
    margin: 1em;
    background-image: url('../../assets/words-exam-lost.png');
    background-size: cover;
    background-position: center;
}

.words-exam-passed {
    width: 100px;
    height: 100px;
    margin: 1em;
    background-image: url('../../assets/words-exam-passed.png');
    background-size: cover;
    background-position: center;
}

.start-exam {
    padding: 0.5em;
    border: 2px solid green;
    text-align: center;
    border-radius: 15px;
    font-weight: 700;
    cursor: pointer;
}

.start-exam:hover {
    font-size: 1.1em;
}

.info-container {
    background-color: white;
    border-radius: 20px;
    border: solid gray 1px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 1em;
    line-height: 1.5;
    font-size: 1.2em;
}

.words-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
}

.words-stats {
    display: flex;
    justify-content: space-around;
    gap: 1em;
}

.best-words {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.worst-words {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.words-info-text {
    font-size: 0.8em;
}

.words-info-title-text {
    font-size: 0.9em;
}

.main-words-information {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2em;
}

.app-container {
    line-height: 2;
}

.about-me-words-mobile {
    display: none;
}

.exam-rules-params {
    display: flex;
    align-items: center;
    gap: 1em;
    border: solid 1px red;
    padding: 0.5em;
    border-radius: 15px;
}

.exam-hearts {
    display: flex;
    align-items: center;
}

.sound-effect-icon {
    font-size: 2em;
}

.phonetic-sound-container {
    display: flex;
    align-items: center;
    gap: 1em;
}

@media (max-width: 800px) {
    .main-container {
        justify-content: center;
    }
    .cards-container {
        width: 80%;
    }
    .tip-left {
        display: none;
    }
    .tip-right {
        display: none;
    }
    .tip-mobile {
        display: block;
        color: gray;
        font-size: 0.8em;
        text-align: center;
        bottom: 0;
    }
    .words-info-text {
        font-size: 0.7em;
    }
    .words-info-title-text {
        font-size: 0.75em;
    }
    .about-me-words-desktop {
        display: none;
    }
    .about-me-words-mobile {
        display: block;
    }
}

@media (max-width: 500px) {
    .cards-container {
        width: 90%;
    }
    .main-words-information {
        width: 80%;
        margin-bottom: 2em;
    }
    .progress-words-container {
        width: 45%;
        gap: 0.5em;
        flex-direction: row-reverse;
    }
    .menu-button-words {
        font-size: 0.9em;
    }
}