.footer {
    background-color: rgba(232, 232, 232, 0.40)
}

.footer li {
    padding: 0.15em;
    display: flex;
    justify-content: center;
}

.footer a {
    text-decoration: none;
}
  
.footer a:hover {
    text-decoration: none;
}