.Listening {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.listening-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    padding: 2em;
    gap: 2em;
}

.listening-container textarea {
    width: 90%;
    min-height: 20vh;
    font-size: large;
    background-color: rgba(239, 239, 239, 0.312);
    padding: 1em;
    resize: vertical;
    border-radius: 5px;
}

.listening-container audio {
    width: 60%;
}

.listening-panel {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    gap: 1em;
}

.button-hide {
    width: 15%;
    border: 1px solid gray;
    border-radius: 5px;
}

.ml-1 {
    margin-left: 0.5em;
}

.listening-audio {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5em;
}

.correct-text {
    color: gray;
}

.listening-container p {
    margin: 0;
}

.tf-container {
    display: flex;
    justify-content: center;
}

.question-tf-container {
    width: 90%;
}

.question-tf-container button {
    width: 40%;
}

.tf-button-container {
    display: flex;
    justify-content: space-around;
}

.tf-info {
    width: 70%;
}

.tf-info h1{
    text-align: center;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
}

.tf-info h2{
    text-align: center;
    margin-bottom: 1em;
}

.tf-info h3{
    text-align: center;
    margin-bottom: 1em;
}

.tf-info p {
    text-indent: 20px;
    line-height: 2em;
}

.tf-info li {
    text-indent: 10px;
    line-height: 1.5em;
    margin-bottom: 1em;
}

@media (max-width: 700px) {
    .listening-container {
        width: 100%;
        padding: 1em;
    }

    .listening-audio {
        width: 100%;
    }
    
    .listening-panel {
        flex-direction: column;
        align-items: center;
    }
    
    .button-hide {
        width: 30%;
    }

    .tf-info {
        width: 85%;
    }
}