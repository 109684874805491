.question {
    width: 90%;
    display: flex;
    justify-content: space-between;
    border: solid black 1px;
    border-radius: 25px;
    box-shadow: 0px 0px 6px 0px;
    background-color: aliceblue;
    padding: 0.5rem 2rem 0.5rem 2rem;
}

.question-text {
    width: 45%;
    font-size: 1.5em;
}

.answer-container {
    width: 45%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
}

@media (max-width: 860px) {
    .question {
        flex-direction: column;
    }
    .question-text {
        width: 90%;
    }
    .answer-container {
        width: 90%;
    }
}