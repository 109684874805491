.answer {
    border: solid black 1px;
    border-radius: 25px;
    padding: 0.5em;
    text-align: center;
    background-color: wheat;
    box-shadow: 0px 0px 6px 0px;
    cursor: pointer;
}

.answer-correct {
    border: solid black 1px;
    border-radius: 25px;
    padding: 0.5em;
    text-align: center;
    background-color: rgb(160, 247, 160);
    box-shadow: 0px 0px 6px 0px;
}

.answer-wrong {
    border: solid black 1px;
    border-radius: 25px;
    padding: 0.5em;
    text-align: center;
    background-color: orangered;
    box-shadow: 0px 0px 6px 0px;
}

.answer:hover {
    background-color: rgb(250, 239, 218);
}

.answer-text {
    font-size: 1em;
    text-wrap: pretty;
}