.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 25px;
    padding-bottom: 25px;
}

.content {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
}

.about-me {
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.feedback-container {
    color: gray;
    text-align: center;
}

.ai-title-quiz {
    color: rgb(87, 87, 87);
    text-align: right;
}

.scroll-to-top-button {
    position: fixed;
    bottom: 20px;
    left: 20px;
    padding: 10px 15px;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease;
  
    &:hover {
      background-color: #0056b3;
    }
  
    &.hidden {
      opacity: 0;
      visibility: hidden;
    }
  
    &.visible {
      opacity: 1;
      visibility: visible;
    }
  }