.ContactUs {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-block {
    width: 80%;
    padding: 2em;
    display: flex;
    flex-direction: column;
    gap: 2em;
}

.contact-block p {
    font-size: large;
}

.cursor-pointer {
    cursor: pointer;
}

@media (max-width: 700px) {
    .contact-block {
        width: 100%;
    }
}