.HowTo {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.how-block {
    width: 80%;
    padding: 2em;
    display: flex;
    flex-direction: column;
    gap: 2em;
}

.how-block h1 {
    text-align: center;
    margin-bottom: 0.5em;
}

.how-block p {
    line-height: 2em;
    font-size: large;
    text-indent: 20px;
}

.how-block li {
    line-height: 2em;
    font-size: large;
    text-indent: 20px;
}

.cursor-pointer {
    cursor: pointer;
}

@media (max-width: 700px) {
    .how-block {
        width: 100%;
    }
}