
.share-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: black;
    padding: 5px 10px;
    border: solid 1px black;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  
    &:hover {
      border-color: gray;
    }
}

@media (max-width: 400px) {
  .share-button {
    font-size: small;
  }
}
  