.header {
    width: 100%;
    font-weight: 500;
}

.header .navbar-brand {
    margin-right: 1em;
    font-weight: 600;
}

.header .nav-link {
    color: black;
}

.nav-header{
    background-color: rgba(232, 232, 232, 0.40);
}

.header hr {
    margin: 0;
}