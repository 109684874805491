.AboutUs {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about-block {
    width: 80%;
    padding: 2em;
    display: flex;
    flex-direction: column;
    gap: 2em;
}

.about-block h1 {
    text-align: center;
}

.about-block p {
    line-height: 2em;
    font-size: large;
    text-indent: 20px;
}

.about-block li {
    line-height: 2em;
    font-size: large;
    text-indent: 20px;
}

.cursor-pointer {
    cursor: pointer;
}

@media (max-width: 700px) {
    .about-block {
        width: 100%;
    }
}