.flag-icon {
    width: 2em;
    height: 1.5em;
    font-size: 0.8em;
    border-radius: 5px;
}

.language-select {
    display: flex;
    align-items: center;
    gap: 0.5em;
}

.language-label {
    display: flex;
    font-size: 0.8em;
}

.language-icon {
    display: flex;
}

.gb {
    background-image: url('../../assets/flags/gb.svg');
    background-size: cover;
    background-position: center;
}

.ua {
    background-image: url('../../assets/flags/ua.svg');
    background-size: cover;
    background-position: center;
}

.tr {
    background-image: url('../../assets/flags/tr.svg');
    background-size: cover;
    background-position: center;
}

.sk {
    background-image: url('../../assets/flags/sk.svg');
    background-size: cover;
    background-position: center;
}

.de {
    background-image: url('../../assets/flags/de.svg');
    background-size: cover;
    background-position: center;
}

.es {
    background-image: url('../../assets/flags/es.svg');
    background-size: cover;
    background-position: center;
}

.pt {
    background-image: url('../../assets/flags/pt.svg');
    background-size: cover;
    background-position: center;
}

.fr {
    background-image: url('../../assets/flags/fr.svg');
    background-size: cover;
    background-position: center;
}

.cn {
    background-image: url('../../assets/flags/cn.svg');
    background-size: cover;
    background-position: center;
}

.it {
    background-image: url('../../assets/flags/it.svg');
    background-size: cover;
    background-position: center;
}